import * as React from "react";
import { StaticImage} from "gatsby-plugin-image";
import { PageContent, Heading, Box, Paragraph} from "grommet";

import Page from "../components/page";
import SEO from "../components/seo";

const IndexPage = () => {
  return (
    <Page kind="narrow">
      <SEO
        title="Commerce chevaux et poneys | Les écuries du cerf pension pour cheval"
        description="Commerce chevaux et poneux aux écuries du cerf en partenariat avec Victoria Goupillot, écuries de propriétaires à Gaudreville-la-Rivière entre Evreux et Conches-en-Ouche dans l'eure 27"
      />
      <PageContent>
        <Heading textAlign="center" alignSelf="center">
          En partenariat avec les écuries Victoria Goupillot
        </Heading>
        <Paragraph fill>
        Nous vous proposons tout au long de l'année des poneys et des chevaux à la vente. Ces équidés sont choisis et travaillés par nos soins pour nous permettre de trouver les propriétaires adéquats à leurs niveaux et perspectives d'évolution.
        </Paragraph>
        <Paragraph fill>
Il est également possible de nous laisser votre équidé en pension en dépôt-vente si vous souhaitez le replacer.
        </Paragraph>
        <Paragraph fill>
Les chevaux qui arrivent chez nous sont en pré-box (paddock la journée et box la nuit) et bénéficient d'un régime adapté à leur physiologie et leur travail.
        </Paragraph>
        <Box height="">
          <StaticImage
            src="../images/ecurie_victoria_goupillot_commerce_chevaux_poney.jpg"
            alt="Victoria Goupillot"
            layout="constrained"
          />
        </Box>
      </PageContent>
    </Page>
  );
};

export default IndexPage;
